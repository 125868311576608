<template>
  <div>
    <v-row>
      <v-col cols="12" md="2">
        <v-card>
          <v-list shaped>
            <v-subheader>Invoice Sections</v-subheader>
            <v-list-item-group color="primary">
              <v-list-item v-for="(section, i) in formSections" :key="i" @click="viewSection(i)">
                <v-list-item-title v-text="section[1].name"></v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
      <v-col cols="12" md="10">
        <v-card>
          <v-card-text>
            <v-container v-if="formSections.length">
              <CustomFormFieldsSetup v-model="formSections[currentIndex][1]" />
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { CustomFormFieldsSetup } from '@/components/shared';
import configApi from '@/apis/config';
export default {
  name: 'InvoiceCustomFormFields',
  data() {
    return {
      formSections: [],
      currentIndex: 0,
    };
  },
  components: {
    CustomFormFieldsSetup,
  },
  methods: {
    viewSection(i) {
      this.currentIndex = i;
    },
  },
  async mounted() {
    try {
      this.formSections = await configApi.getCustomFormFields('Invoice');
    } catch (error) {
      this.$myalert.error(error?.message);
    }
  },
};
</script>
